/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

import EditIcon from '@mui/icons-material/Edit';
import { DeleteForever } from "@mui/icons-material";


export default function data({ data, handleEditOpen, handleOpen2 }) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const rows = data.map(item => ({
    reportId: item.reportId,
    reportTitle: <Job title={item.reportTitle} />,
    reportCategory: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {item.reportCategory} {/* Replace this with the correct field from your data */}
      </MDTypography>
    ),
    singlePrice: <span style={{ width: "100px", display: "block" }}>{item.priceForSingleUser}</span>,
    enterprisePrice: <span style={{ width: "100px", display: "block" }}>{item.priceForEnterpriseUser}</span>,
    isActive: (
      <MDBox ml={-1}>
        <MDBadge badgeContent={item.isActive ? "True" : "False"} color={item.isActive ? "success" : "dark"} variant="gradient" size="sm" />
      </MDBox>
    ),
    action: (
      <>
        <MDTypography component="button" variant="caption" color="text" fontWeight="medium" fontSize="20px">
          <span onClick={() => handleEditOpen(item.reportId)}><EditIcon /></span>
        </MDTypography>
        <MDTypography component="button" variant="caption" color="text" fontWeight="medium" fontSize="20px">
          <span onClick={() => handleOpen2(item.reportId)}><DeleteForever /></span>
        </MDTypography>
      </>
    ),
  }));

  return {
    columns: [
      { Header: "Report Id", accessor: "reportId", width: "150px", align: "left" },
      { Header: "Report Title", accessor: "reportTitle", align: "left" },
      { Header: "Report Category", accessor: "reportCategory", align: "center" },
      { Header: "Price For Single User", accessor: "singlePrice", width: "100px", align: "center" },
      { Header: "Price For Enterprise User", accessor: "enterprisePrice", width: "100px", align: "center" },
      { Header: "Is Active", accessor: "isActive", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    // rows: [
    //   {
    //     reportId: <Author image={team2} name="John Michael" email="john@creative-tim.com" />,
    //     reportTitle: <Job title="Manager" description="Organization" />,
    //     isActive: (
    //       <MDBox ml={-1}>
    //         <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
    //       </MDBox>
    //     ),
    //     reportCategory: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         23/04/18
    //       </MDTypography>
    //     ),
    //     action: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         Edit
    //       </MDTypography>
    //     ),
    //   },
    // ],

    rows: rows
  };
}
