import { useEffect, useState } from "react";
import axios from "axios";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import usersTableData from "layouts/tables/data/usersTableData";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Button } from "@mui/material";
import UserModal from "layouts/modal/userModal/UserModal";
import DeleteModal from "layouts/modal/userModal/DeleteModal";
import MDInput from "components/MDInput";

const UserManagement = () => {
    const [btnWarn, setBtnWarn] = useState(false)
    const [firstNameWarn, setFirstNameWarn] = useState(false)
    const [lastNameWarn, setLastNameWarn] = useState(false)
    const [emailWarn, setEmailWarn] = useState(false)
    const [passwordWarn, setPasswordWarn] = useState(false)
    const [roleIDWarn, setRoleIDWarn] = useState(false)

    const [allRoles, setAllRoles] = useState([])
    const [currentUserRole, setCurrentUserRole] = useState("")

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [storeData, setStoreData] = useState([]);

    const [userID, setUserID] = useState(0)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [roleID, setRoleID] = useState(0)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [isActive, setIsActive] = useState("true")

    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)

    const clearStates = () => {
        setBtnWarn(false)
        setFirstNameWarn(false)
        setLastNameWarn(false)
        setEmailWarn(false)
        setPasswordWarn(false)
        setRoleIDWarn(false)

        setUserID(0)
        setEmail("")
        setPassword("")
        setFirstName("")
        setLastName("")
        setIsActive("true")
    }

    const handleOpen = () => {
        clearStates()
        setUserID(0)
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setIsUpdate(false)
        clearStates()
    }

    const handleOpen2 = (id) => {
        setOpen2(true)
        setUserID(id)
    }
    const handleClose2 = () => {
        setOpen2(false)
        setUserID(0)
    }

    const handleEditOpen = (id) => {
        setIsUpdate(true)
        clearStates()

        setOpen(true)
        console.log(`hi ${id}`);
        setUserID(id)
        axios.get(`https://api.absolutemarketresearch.com/api/User/GetAllUsers?userid=${id}`).then(res => {
            console.log(res.data);
            const data = res.data[0]
            setUserID(data.userID)
            setEmail(data.email)
            setPassword(data.password)
            setRoleID(data.roleID)
            setFirstName(data.firstName)
            setLastName(data.lastName)
            setIsActive(JSON.stringify(data.isActive))
        }).catch(err => {
            console.log(err);
        })
    }
    const handleSave = () => {
        let emptyFieldCount = 0;
        setBtnWarn(false);
        if (!firstName) {
            setFirstNameWarn(true)
            emptyFieldCount++
        } else {
            setFirstNameWarn(false)
        }
        if (!lastName) {
            setLastNameWarn(true)
            emptyFieldCount++
        } else {
            setLastNameWarn(false)
        }
        if (!email) {
            setEmailWarn(true)
            emptyFieldCount++
        } else {
            setEmailWarn(false)
        }
        if (!password) {
            setPasswordWarn(true)
            emptyFieldCount++
        } else {
            setPasswordWarn(false)
        }
        if (!roleID) {
            setRoleIDWarn(true)
            emptyFieldCount++
        } else {
            setRoleIDWarn(false)
        }

        if (emptyFieldCount > 0) {
            setBtnWarn(true)
        } else {
            axios.post("https://api.absolutemarketresearch.com/api/User/RegisterUser", {
                userID: Number(userID),
                email,
                password,
                roleID: Number(roleID),
                firstName,
                lastName,
                isActive: JSON.parse(isActive)
            }).then(res => {
                console.log(res.data);
                setOpen(false)
            }).catch(err => {
                console.log(err);
            })
        }
    }
    const handleUpdate = () => {
        console.log(userID);
        axios.put(`https://api.absolutemarketresearch.com/api/User/${userID}`, {
            userID,
            email,
            password,
            roleID,
            firstName,
            lastName,
            isActive: JSON.parse(isActive)
        }).then(res => {
            console.log(res.data);
            setOpen(false)
        }).catch(err => {
            console.log(err);
        })
    }
    const handleDelete = () => {
        axios.delete(`https://api.absolutemarketresearch.com/api/User/${userID}`).then(res => {
            console.log(res.data);
            setOpen2(false)
        }).catch(err => {
            console.log(err);
        })
    }

    const { columns, rows } = usersTableData({ data: filteredData, handleEditOpen, handleOpen2, currentUserRole });


    useEffect(() => {
        axios.get(process.env.REACT_APP_GET_ALL_USERS_API).then(res => {
            console.log(res.data);
            setStoreData(res.data);
        }).catch(err => {
            console.log(err);
        })

        axios.get("https://api.absolutemarketresearch.com/api/User/GetAllRoles").then(res => {
            console.log(res.data);
            setAllRoles(res.data);
            setRoleID(res.data[0].roleID)
        })

        const getUser = JSON.parse(sessionStorage.getItem("menuArray"))
        setCurrentUserRole(getUser[0].role)
    }, []);


    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };
    useEffect(() => {
        if (searchTerm === '') {
            setFilteredData(storeData);
        } else {
            const filteredResults = storeData.filter(item =>
                (item.firstName && item.firstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.lastName && item.lastName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.email && item.email.toLowerCase().includes(searchTerm.toLowerCase()))
            );
            setFilteredData(filteredResults);
        }
    }, [searchTerm, storeData]);

    return (
        <div className="userManagement">
            <DashboardLayout>
                <DashboardNavbar />
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                >
                                    <MDTypography variant="h6" color="white">
                                        <span>All Users</span>
                                    </MDTypography>
                                    <div>
                                        <MDBox pr={1} className="navBarInputWrap" style={{ marginRight: "20px" }}>
                                            <MDInput label="Search here" value={searchTerm} onChange={handleInputChange} style={{ width: "200px" }} />
                                        </MDBox>
                                        {
                                            (currentUserRole === "Admin" || currentUserRole === "SuperAdmin") &&
                                            <Button style={{ color: "#1A73E8", backgroundColor: "#fff" }} onClick={handleOpen}>Add New User</Button>
                                        }
                                    </div>
                                </MDBox>
                                <MDBox pt={3}>
                                    <DataTable
                                        table={{ columns, rows }}
                                        isSorted={false}
                                        entriesPerPage={false}
                                        showTotalEntries={false}
                                        noEndBorder
                                    />
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
            <UserModal
                open={open}
                handleClose={handleClose}
                handleSave={handleSave}
                handleUpdate={handleUpdate}
                isUpdate={isUpdate}
                userID={userID}
                email={email}
                password={password}
                roleID={roleID}
                firstName={firstName}
                lastName={lastName}
                isActive={isActive}
                setUserID={setUserID}
                setEmail={setEmail}
                setPassword={setPassword}
                setRoleID={setRoleID}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setIsActive={setIsActive}

                btnWarn={btnWarn}
                firstNameWarn={firstNameWarn}
                lastNameWarn={lastNameWarn}
                emailWarn={emailWarn}
                passwordWarn={passwordWarn}
                roleIDWarn={roleIDWarn}

                allRoles={allRoles}
            />
            <DeleteModal
                open={open2}
                handleClose={handleClose2}
                userID={userID}
                handleDelete={handleDelete}
            />
        </div>
    )
}

export default UserManagement