/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

import EditIcon from '@mui/icons-material/Edit';
import { DeleteForever } from "@mui/icons-material";


export default function data({ data, handleEditOpen, handleOpen2, currentUserRole }) {
    const User = ({ image, name, email }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDAvatar src={image} name={name} size="sm" />
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {name}
                </MDTypography>
                <MDTypography variant="caption">{email}</MDTypography>
            </MDBox>
        </MDBox>
    );

    const CellItem = ({ title, width }) => (
        <MDBox lineHeight={1} textAlign="left" width={width}>
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {title}
            </MDTypography>
        </MDBox>
    );
    
    const rows = data.map(item => ({
        userId: item.userID,
        userFirstName: <CellItem title={item.firstName} width="fit-content" />,
        userLastName: <CellItem title={item.lastName} width="100px" />,
        userEmail: <CellItem title={item.email} width="fit-content" />,
        userActive: <CellItem title={JSON.stringify(item.isActive)} width="fit-content" />,
        action: (
            (currentUserRole === "Admin" || currentUserRole === "SuperAdmin") &&
            <>
                <MDTypography component="button" variant="caption" color="text" fontWeight="medium" fontSize="20px">
                    <span onClick={() => handleEditOpen(item.userID)}><EditIcon /></span>
                </MDTypography>
                <MDTypography component="button" variant="caption" color="text" fontWeight="medium" fontSize="20px">
                    <span onClick={() => handleOpen2(item.userID)}><DeleteForever /></span>
                </MDTypography>
            </>
        ),
    }));

    return {
        columns: [
            { Header: "User Id", accessor: "userId", width: "200px", align: "left" },
            { Header: "First Name", accessor: "userFirstName", align: "left" },
            { Header: "Last Name", accessor: "userLastName", width: "150px", align: "left" },
            { Header: "Email", accessor: "userEmail", align: "left" },
            { Header: "Is Active", accessor: "userActive", align: "center" },
            ...(currentUserRole === "Admin" || currentUserRole === "SuperAdmin" ? [{ Header: "Action", accessor: "action", align: "center" }] : []),
        ],

        rows: rows
    };
}
