import { Box, Button, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "450px",
    maxWidth: "100%",
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: "10px",
    display: "block",
    boxShadow: 24,
    p: 4,
    maxHeight: "100vh",
    overflowX: "clip",
    overflowY: "auto",
};



const DeleteModal = ({ open, handleClose, singleReportId, handleDelete }) => {


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h4" component="h2">
                    Confirm Delete
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <span style={{ display: "block", marginBottom: "35px" }}>Are you sure want to delete Report no. "{singleReportId}" ?</span>
                    <div className="delButtonWrap" style={{ display: "flex", alignItems: "center" }}>
                        <Button onClick={handleDelete} style={{ backgroundColor: "red", color: "#fff" }}>Delete</Button>
                        <Button variant='contained' onClick={handleClose} style={{ marginLeft: "10px", backgroundColor: "#fff", color: "#000" }}>Cancel</Button>
                    </div>
                </Typography>
            </Box>
        </Modal>
    )
}

export default DeleteModal