import { Box, Button, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import close from '../../../assets/images/close.png'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "85%",
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: "10px",
    display: "block",
    boxShadow: 24,
    p: 4,
    maxHeight: "100vh",
    overflowX: "clip",
    overflowY: "auto",
};



const ReportModal = ({ open, handleClose, handleSave, handleUpdate, isUpdate, storeAllCat,
    setSingleReportId,
    setRepTitle,
    setIsActive,
    setPriceSingle,
    setEnterPrice,
    setPublisedBy,
    setCategory,
    setRating,
    setSequence,
    setPublishedOn,
    setStartdate,
    setEndDate,
    setRepPage,
    setCreatedBy,
    setCreatedOn,
    setUpdatedBy,
    setUpdatedOn,
    setRepDescription,
    setRepTableOfContent,
    singleReportId,
    repTitle,
    isActive,
    priceSingle,
    enterPrice,
    corporate,
    publisedBy,
    category,
    rating,
    sequence,
    publishedOn,
    startdate,
    endDate,
    repPage,
    createdBy,
    createdOn,
    updatedBy,
    updatedOn,
    repDescription,
    repTableOfContent,

    btnWarn,
    singleReportIdWarn,
    repTitleWarn,
    repDescriptionWarn,
    priceSingleWarn,
    publishedOnWarn,
    repPageWarn
}) => {


    function capitalizeWords(str) {
        return str.toLowerCase().replace(/(^|\s)\S/g, function (firstLetter) {
            return firstLetter.toUpperCase();
        });
    }



    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h4" component="h2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>{isUpdate ? "Edit" : "Create"} Report</span>
                    <img src={close} alt="" style={{ width: "20px", cursor: "pointer" }} onClick={handleClose} />
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <div className="reportEdit editForm">
                        <div className='form'>
                            <div className="editWrap">
                                {
                                    !isUpdate &&
                                    <div className="singleEditInp">
                                        <label htmlFor="repId">report id</label>
                                        <input type="text" name="repId" id="repId" value={singleReportId} onChange={e => setSingleReportId(e.target.value)} />
                                        {singleReportIdWarn && <small className="warnText">*This field is required!</small>}
                                    </div>
                                }
                                <div className="singleEditInp">
                                    <label htmlFor="repTitle">report title</label>
                                    <input type="text" name="repTitle" id="repTitle" value={repTitle} onChange={e => setRepTitle(e.target.value)} />
                                    {repTitleWarn && <small className="warnText">*This field is required!</small>}
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="repActive">is active</label>
                                    <select name="repActive" id="repActive" value={isActive} onChange={e => setIsActive(e.target.value)}>
                                        <option value="true">true</option>
                                        <option value="false">false</option>
                                    </select>
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="singlePrice">price for single user</label>
                                    <input type="text" name="singlePrice" id="singlePrice" value={priceSingle} onChange={e => setPriceSingle(e.target.value)} />
                                    {priceSingleWarn && <small className="warnText">*This field is required!</small>}
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="enterprisePrice">price for enterprise user</label>
                                    <input type="text" name="enterprisePrice" id="enterprisePrice" value={enterPrice} onChange={e => setEnterPrice(e.target.value)} />
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="corporatePrice">price for corporate user</label>
                                    <input type="text" name="corporatePrice" id="corporatePrice" />
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="publishedBy">published by</label>
                                    <input type="text" name="publishedBy" id="publishedBy" value={publisedBy} onChange={e => setPublisedBy(e.target.value)} />
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="corporatePrice">report category</label>
                                    <select name="catEdit" id="catEdit" value={capitalizeWords(category)} onChange={e => setCategory(e.target.value)}>
                                        {
                                            storeAllCat.map((val, i) => {
                                                return <option value={val.categoryName}>{val.categoryName}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="editorRating">editors rating</label>
                                    <input type="text" name="editorRating" id="editorRating" value={rating} onChange={e => setRating(e.target.value)} />
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="sequence">sequence</label>
                                    <input type="text" name="sequence" id="sequence" value={sequence} onChange={e => setSequence(e.target.value)} />
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="publishedOn">published on</label>
                                    <input type="datetime-local" name="publishedOn" id="publishedOn" value={publishedOn} onChange={e => setPublishedOn(e.target.value)} />
                                    {publishedOnWarn && <small className="warnText">*This field is required!</small>}
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="startDate">start date</label>
                                    <input type="datetime-local" name="startDate" id="startDate" value={startdate} onChange={e => setStartdate(e.target.value)} />
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="endDate">end date</label>
                                    <input type="datetime-local" name="endDate" id="endDate" value={endDate} onChange={e => setEndDate(e.target.value)} />
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="repPage">report pages</label>
                                    <input type="text" name="repPage" id="repPage" value={repPage} onChange={e => setRepPage(e.target.value)} />
                                    {repPageWarn && <small className="warnText">*This field is required!</small>}
                                </div>
                                {
                                    isUpdate &&
                                    <div className="singleEditInp">
                                        <label htmlFor="createdBy">created by</label>
                                        <input type="text" name="createdBy" id="createdBy" value={createdBy} disabled readOnly />
                                    </div>
                                }
                                {
                                    isUpdate &&
                                    <div className="singleEditInp">
                                        <label htmlFor="createdOn">created on</label>
                                        <input type="text" name="createdOn" id="createdOn" value={createdOn} onChange={e => setCreatedOn(e.target.value)} />
                                    </div>
                                }
                                {
                                    isUpdate &&
                                    <div className="singleEditInp">
                                        <label htmlFor="updatedBy">updated by</label>
                                        <input type="text" name="updatedBy" id="updatedBy" value={updatedBy} onChange={e => setUpdatedBy(e.target.value)} />
                                    </div>
                                }
                                {
                                    isUpdate &&
                                    <div className="singleEditInp">
                                        <label htmlFor="updatedOn">updated on</label>
                                        <input type="text" name="updatedOn" id="updatedOn" value={updatedOn} onChange={e => setUpdatedOn(e.target.value)} />
                                    </div>
                                }
                                <div className="singleEditInp singleEditFull">
                                    <label htmlFor="repDescription">report description</label>
                                    <textarea name="repDescription" value={repDescription} onChange={e => setRepDescription(e.target.value)}></textarea>
                                    {repDescriptionWarn && <small className="warnText">*This field is required!</small>}
                                </div>
                                <div className="singleEditInp singleEditFull">
                                    <label htmlFor="repTableOfContent">table of content</label>
                                    <textarea name="repTableOfContent" value={repTableOfContent} onChange={e => setRepTableOfContent(e.target.value)}></textarea>
                                </div>
                            </div>
                            <Button onClick={handleClose} variant='contained' style={{ marginTop: "15px", marginRight: "15px", backgroundColor: "#fff" }}>Cancel</Button>
                            <Button onClick={isUpdate ? handleUpdate : handleSave} variant="contained" style={{ color: "#fff", marginTop: "15px" }} type='submit'>{isUpdate ? "Update" : "Submit"}</Button>
                            {btnWarn && <small className="warnBtnText">*Please fill all required fields!</small>}
                        </div>
                    </div>
                </Typography>
            </Box>
        </Modal>
    )
}

export default ReportModal