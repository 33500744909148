import { Box, Button, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import close from '../../../assets/images/close.png'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "350px",
    maxWidth: "85%",
    // bgcolor: 'background.paper',
    border: 'none',
    borderRadius: "10px",
    display: "block",
    boxShadow: 24,
    // p: 4,
    maxHeight: "100vh",
    overflowX: "clip",
    overflowY: "auto",
};

const UserModal = ({ open, handleClose, handleSave, handleUpdate, isUpdate,
    userID,
    email,
    password,
    roleID,
    firstName,
    lastName,
    isActive,
    setUserID,
    setEmail,
    setPassword,
    setRoleID,
    setFirstName,
    setLastName,
    setIsActive,

    allRoles,

    btnWarn,
    firstNameWarn,
    lastNameWarn,
    emailWarn,
    passwordWarn,
    roleIDWarn,
}) => {

    const handleChange = (event) => {
        setIsActive(event.target.value);
    };

    const handleRole = (e) => {
        console.log(e.target.value);
        setRoleID(e.target.value)
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Card>
                    <MDBox pt={4} pb={3} px={3}>
                        <Typography id="modal-modal-title" variant="h5" component="h2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "15px" }}>
                            <span>{isUpdate ? "Edit" : "Create"} User</span>
                            <img src={close} alt="" style={{ width: "17px", cursor: "pointer" }} onClick={handleClose} />
                        </Typography>
                        <MDBox component="form" role="form">
                            <MDBox className="boxRel" mb={2}>
                                <MDInput type="text" label="First Name" variant="standard" fullWidth value={firstName} onChange={e => setFirstName(e.target.value)} />
                                {firstNameWarn && <small className="warnText">*This field is required!</small>}
                            </MDBox>
                            <MDBox className="boxRel" mb={2}>
                                <MDInput type="text" label="Last Name" variant="standard" fullWidth value={lastName} onChange={e => setLastName(e.target.value)} />
                                {lastNameWarn && <small className="warnText">*This field is required!</small>}
                            </MDBox>
                            <MDBox className="boxRel" mb={2}>
                                <MDInput type="email" label="Email" variant="standard" fullWidth value={email} onChange={e => setEmail(e.target.value)} />
                                {emailWarn && <small className="warnText">*This field is required!</small>}
                            </MDBox>
                            <MDBox className="boxRel" mb={2}>
                                <MDInput type="password" label="Password" variant="standard" fullWidth value={password} onChange={e => setPassword(e.target.value)} />
                                {passwordWarn && <small className="warnText">*This field is required!</small>}
                            </MDBox>
                            <MDBox className="boxRel" mb={2}>
                                <MDInput type="number" label="User Id" variant="standard" fullWidth value={userID} onChange={e => setUserID(e.target.value)} />
                            </MDBox>
                            {/* <MDBox mb={2}>
                                <MDInput type="number" label="Role Id" variant="standard" fullWidth value={roleID} onChange={e => setRoleID(e.target.value)} />
                            </MDBox> */}
                            <FormControl variant="standard" mb={2} fullWidth>
                                <InputLabel id="demo-simple-select-standard-label1">Role Id</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label1"
                                    // id="demo-simple-select-standard"
                                    value={roleID}
                                    onChange={handleRole}
                                    label="Role Id"
                                    variant="standard"
                                >
                                    {/* <MenuItem value="Select Role" disabled>Select Role</MenuItem> */}
                                    {
                                        allRoles.map((val, i) => {
                                            return <MenuItem value={val.roleID} key={i}>{val.roleName}</MenuItem>
                                        })
                                    }
                                </Select>
                                {roleIDWarn && <small className="warnText">*This field is required!</small>}
                            </FormControl>
                            {
                                isUpdate &&
                                <FormControl variant="standard" mb={2} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Is Active</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        // id="demo-simple-select-standard"
                                        value={isActive}
                                        onChange={handleChange}
                                        label="Is Active"
                                        variant="standard"
                                    >
                                        {/* <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem> */}
                                        <MenuItem value={"true"}>True</MenuItem>
                                        <MenuItem value={"false"}>False</MenuItem>
                                    </Select>
                                </FormControl>
                            }
                            <MDBox mt={4} mb={1}>
                                <MDButton variant="gradient" color="info" fullWidth onClick={isUpdate ? handleUpdate : handleSave}>
                                    {
                                        isUpdate ? "Update User" : "Add User"
                                    }
                                </MDButton>
                                {btnWarn && <small className="warnBtnText">*Please fill all required fields!</small>}
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Card>
            </Box>
        </Modal>
    )
}

export default UserModal