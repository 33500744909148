import { useEffect, useState } from "react";
import axios from "axios";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import authorsTableData from "layouts/tables/data/authorsTableData";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ReportModal from "layouts/modal/reportModal/ReportModal";
import { Button } from "@mui/material";
import DeleteModal from "layouts/modal/reportModal/DeleteModal";
import MDInput from "components/MDInput";

const ReportManagement = () => {
    const [btnWarn, setBtnWarn] = useState(false)
    const [singleReportIdWarn, setSingleReportIdWarn] = useState(false)
    const [repTitleWarn, setRepTitleWarn] = useState(false)
    const [repDescriptionWarn, setRepDescriptionWarn] = useState(false)
    const [priceSingleWarn, setPriceSingleWarn] = useState(false)
    const [publishedOnWarn, setPublishedOnWarn] = useState(false)
    const [repPageWarn, setRepPageWarn] = useState(false)

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [storeData, setStoreData] = useState([]);
    const [storeAllCat, setStoreAllCat] = useState([])
    const [singleReportId, setSingleReportId] = useState(null)

    const [isUpdate, setIsUpdate] = useState(false)

    const [repTitle, setRepTitle] = useState("")
    const [isActive, setIsActive] = useState("true")
    const [priceSingle, setPriceSingle] = useState("")
    const [enterPrice, setEnterPrice] = useState("")
    const [corporate, setCorporate] = useState("")
    const [publisedBy, setPublisedBy] = useState("")
    const [category, setCategory] = useState("")
    const [rating, setRating] = useState("")
    const [sequence, setSequence] = useState(0)
    const [publishedOn, setPublishedOn] = useState("")
    const [startdate, setStartdate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [repPage, setRepPage] = useState("")
    const [createdBy, setCreatedBy] = useState("")
    const [createdOn, setCreatedOn] = useState("")
    const [updatedBy, setUpdatedBy] = useState("")
    const [updatedOn, setUpdatedOn] = useState("")
    const [repDescription, setRepDescription] = useState("")
    const [repTableOfContent, setRepTableOfContent] = useState("")


    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)

    const clearStates = () => {
        setBtnWarn(false)
        setSingleReportIdWarn(false)
        setRepTitleWarn(false)
        setRepDescriptionWarn(false)
        setPriceSingleWarn(false)
        setPublishedOnWarn(false)
        setRepPageWarn(false)


        setRepTitle("")
        setIsActive("true")
        setPriceSingle("")
        setEnterPrice("")
        setPublisedBy("")
        setCategory("")
        setRating("")
        setSequence(0)
        setPublishedOn("")
        setStartdate("")
        setEndDate("")
        setRepPage("")
        setCreatedBy("")
        setCreatedOn("")
        setUpdatedBy("")
        setUpdatedOn("")
        setRepDescription("")
        setRepTableOfContent("")
    }

    const handleOpen = () => {
        clearStates()
        setSingleReportId("")
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setIsUpdate(false)
        clearStates()
    }
    const handleOpen2 = (id) => {
        setOpen2(true)
        setSingleReportId(id)
    }
    const handleClose2 = () => {
        setOpen2(false)
        setSingleReportId("")
    }


    const handleEditOpen = (id) => {
        setIsUpdate(true)
        clearStates()

        setOpen(true)
        console.log(`hi ${id}`);
        setSingleReportId(id)
        axios.get(`https://api.absolutemarketresearch.com/api/Report/GetAllReportsWithLimit?reportId=${id}`).then(res => {
            console.log(res.data[0]);
            const data = res.data[0]
            setRepTitle(data.reportTitle)
            setIsActive(JSON.stringify(data.isActive))
            setPriceSingle(data.priceForSingleUser)
            setEnterPrice(data.priceForEnterpriseUser)
            setPublisedBy(data.publishedBy)
            setCategory(data.reportCategory)
            setRating(data.editorRating)
            setSequence(data.sequence)
            setPublishedOn(data.publishedOn)
            setStartdate(data.startDate)
            setEndDate(data.endDate)
            setRepPage(data.reportPages)
            setRepDescription(data.reportDescription)
            setRepTableOfContent(data.reportTableOfContent)
        }).catch(err => {
            console.log(err);
        })
    }

    const handleSave = () => {
        let emptyFieldCount = 0;
        setBtnWarn(false);
        if (!singleReportId) {
            setSingleReportIdWarn(true)
            emptyFieldCount++
        } else {
            setSingleReportIdWarn(false)
        }
        if (!repTitle) {
            setRepTitleWarn(true)
            emptyFieldCount++
        } else {
            setRepTitleWarn(false)
        }
        if (!priceSingle) {
            setPriceSingleWarn(true)
            emptyFieldCount++
        } else {
            setPriceSingleWarn(false)
        }
        if (!repPage) {
            setRepPageWarn(true)
            emptyFieldCount++
        } else {
            setRepPageWarn(false)
        }
        if (!publishedOn) {
            setPublishedOnWarn(true)
            emptyFieldCount++
        } else {
            setPublishedOnWarn(false)
        }
        if (!repDescription) {
            setRepDescriptionWarn(true)
            emptyFieldCount++
        } else {
            setRepDescriptionWarn(false)
        }

        if (emptyFieldCount > 0) {
            setBtnWarn(true)
        } else {
            axios.post("https://api.absolutemarketresearch.com/api/Report/CreateReport", {
                reportId: singleReportId,
                reportTitle: repTitle,
                reportCategory: category,
                reportPages: Number(repPage),
                priceForSingleUser: Number(priceSingle),
                publishedOn: publishedOn,
                reportDescription: repDescription,
                reportTableOfContent: repTableOfContent,
                publishedBy: publisedBy,
                sequence: Number(sequence),
                editorRating: rating,
                priceForEnterpriseUser: Number(enterPrice),
                startDate: startdate,
                endDate: endDate,
                isActive: JSON.parse(isActive),
                createdBy: createdBy,
                createdOn: createdOn,
                updatedBy: updatedBy,
                updatedOn: updatedOn
            }).then(res => {
                console.log(res.data);
                setOpen(false)
            }).catch(err => {
                console.log(err);
            })
        }

        console.log(emptyFieldCount);
        console.log(repTitle);
        console.log(repTitleWarn);
    }
    const handleUpdate = () => {
        console.log(singleReportId);
        axios.put(`https://api.absolutemarketresearch.com/api/Report/UpdateReport/${singleReportId}`, {
            reportTitle: repTitle,
            reportCategory: category,
            reportPages: Number(repPage),
            priceForSingleUser: Number(priceSingle),
            publishedOn: publishedOn,
            reportDescription: repDescription,
            reportTableOfContent: repTableOfContent,
            publishedBy: publisedBy,
            sequence: Number(sequence),
            editorRating: rating,
            priceForEnterpriseUser: Number(enterPrice),
            startDate: startdate,
            endDate: endDate,
            isActive: isActive,
        }).then(res => {
            console.log(res.data);
            setOpen(false)
        }).catch(err => {
            console.log(err);
        })
    }
    const handleDelete = () => {
        console.log(singleReportId);
        axios.delete(`https://api.absolutemarketresearch.com/api/Report/DeleteReport/${singleReportId}`).then(res => {
            console.log(res.data);
            setOpen2(false)
        }).catch(err => {
            console.log(err);
        })
    }


    const handleExport = () => {
        axios.get("https://api.absolutemarketresearch.com/api/Report/ExportExcelData", {
            responseType: 'blob' // Handle response as binary data
        }).then(res => {
            const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Exported_ReportsData_PromotionalLinks.xlsx';
            document.body.appendChild(a);

            a.click();

            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.error('Error downloading file:', error);
        });
    }


    const { columns, rows } = authorsTableData({ data: filteredData, handleEditOpen, handleOpen2 });


    useEffect(() => {
        axios.get(process.env.REACT_APP_GET_LIMITED_REPORTS_API).then(res => {
            console.log(res.data);
            setStoreData(res.data);
        }).catch(err => {
            console.log(err);
        })
        axios.get(process.env.REACT_APP_GET_ALL_CATEGORIES_API).then(res => {
            console.log(res.data);
            setStoreAllCat(res.data)
        }).catch(err => {
            console.log(err);
        })
    }, []);


    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };
    useEffect(() => {
        if (searchTerm === '') {
            setFilteredData(storeData);
        } else {
            const filteredResults = storeData.filter(item =>
                (item.reportTitle && item.reportTitle.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.reportDescription && item.reportDescription.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.reportCategory && item.reportCategory.toLowerCase().includes(searchTerm.toLowerCase()))
            );
            setFilteredData(filteredResults);
        }
    }, [searchTerm, storeData]);


    return (
        <div className="reportManagement">
            <DashboardLayout>
                <DashboardNavbar />
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                >
                                    <MDTypography variant="h6" color="white">
                                        <span>All Reports</span>
                                    </MDTypography>
                                    <div>
                                        <div>
                                            <MDBox pr={1} className="navBarInputWrap" style={{ marginRight: "20px" }}>
                                                <MDInput label="Search here" value={searchTerm} onChange={handleInputChange} style={{ width: "200px" }} />
                                            </MDBox>
                                            <Button style={{ color: "#1A73E8", backgroundColor: "#fff" }} onClick={handleOpen}>Create Report</Button>
                                            <Button style={{ color: "#1A73E8", backgroundColor: "#fff", marginLeft: "15px" }} onClick={handleExport}>Export</Button>
                                        </div>
                                    </div>
                                </MDBox>
                                <MDBox pt={3}>
                                    <DataTable
                                        table={{ columns, rows }}
                                        isSorted={false}
                                        entriesPerPage={false}
                                        showTotalEntries={false}
                                        noEndBorder
                                    />
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
            <ReportModal
                open={open}
                handleClose={handleClose}
                handleSave={handleSave}
                handleUpdate={handleUpdate}
                isUpdate={isUpdate}
                storeAllCat={storeAllCat}
                setSingleReportId={setSingleReportId}
                setRepTitle={setRepTitle}
                setIsActive={setIsActive}
                setPriceSingle={setPriceSingle}
                setEnterPrice={setEnterPrice}
                setPublisedBy={setPublisedBy}
                setCategory={setCategory}
                setRating={setRating}
                setSequence={setSequence}
                setPublishedOn={setPublishedOn}
                setStartdate={setStartdate}
                setEndDate={setEndDate}
                setRepPage={setRepPage}
                setCreatedBy={setCreatedBy}
                setCreatedOn={setCreatedOn}
                setUpdatedBy={setUpdatedBy}
                setUpdatedOn={setUpdatedOn}
                setRepDescription={setRepDescription}
                setRepTableOfContent={setRepTableOfContent}
                singleReportId={singleReportId}
                repTitle={repTitle}
                isActive={isActive}
                priceSingle={priceSingle}
                enterPrice={enterPrice}
                corporate={corporate}
                publisedBy={publisedBy}
                category={category}
                rating={rating}
                sequence={sequence}
                publishedOn={publishedOn}
                startdate={startdate}
                endDate={endDate}
                repPage={repPage}
                createdBy={createdBy}
                createdOn={createdOn}
                updatedBy={updatedBy}
                updatedOn={updatedOn}
                repDescription={repDescription}
                repTableOfContent={repTableOfContent}

                btnWarn={btnWarn}
                singleReportIdWarn={singleReportIdWarn}
                repTitleWarn={repTitleWarn}
                repDescriptionWarn={repDescriptionWarn}
                priceSingleWarn={priceSingleWarn}
                publishedOnWarn={publishedOnWarn}
                repPageWarn={repPageWarn}
            />
            <DeleteModal
                open={open2}
                handleClose={handleClose2}
                singleReportId={singleReportId}
                handleDelete={handleDelete}
            />
        </div>
    );

};

export default ReportManagement;
