import { useEffect, useState } from "react";
import axios from "axios";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import categoriesTableData from 'layouts/tables/data/categoriesTableData'
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { Button } from "@mui/material";
import CategoryModal from "layouts/modal/categoryModal/CategoryModal";
import DeleteModal from "layouts/modal/categoryModal/DeleteModal";
import MDInput from "components/MDInput";

const CategoryManagement = () => {
    const [btnWarn, setBtnWarn] = useState(false)
    const [categoryNameWarn, setCategoryNameWarn] = useState(false)

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [storeData, setStoreData] = useState([]);
    const [categoryId, setCategoryId] = useState("")
    const [categoryName, setCategoryName] = useState("")
    const [categoryNameWithReportCount, setCategoryNameWithReportCount] = useState("")
    const [parentCategory, setParentCategory] = useState("")
    const [categoryImageURL, setCategoryImageURL] = useState("")
    const [isOnHomepage, setIsOnHomepage] = useState("")
    const [isActive, setIsActive] = useState("true")

    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)



    const clearStates = () => {
        setBtnWarn(false)
        setCategoryNameWarn(false)

        setCategoryId("")
        setCategoryName("")
        setCategoryNameWithReportCount("")
        setParentCategory("")
        setCategoryImageURL("")
        setIsOnHomepage("")
        setIsActive("true")
    }

    const handleOpen = () => {
        clearStates()
        setCategoryId("")
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setIsUpdate(false)
        clearStates()
    }
    const handleOpen2 = (id) => {
        setOpen2(true)
        setCategoryId(id)
    }
    const handleClose2 = () => {
        setOpen2(false)
        setCategoryId("")
    }

    const handleEditOpen = (id) => {
        setIsUpdate(true)
        clearStates()

        setOpen(true)
        console.log(`hi ${id}`);
        setCategoryId(id)
        axios.get(`https://api.absolutemarketresearch.com/api/Category/${id}`).then(res => {
            console.log(res.data);
            const data = res.data
            setCategoryId(data.categoryId)
            setCategoryName(data.categoryName)
            setCategoryNameWithReportCount(data.categoryNameWithReportCount)
            setParentCategory(data.parentCategory)
            setCategoryImageURL(data.categoryImageURL)
            setIsOnHomepage(data.isOnHomepage)
            setIsActive(JSON.stringify(data.isActive))
        }).catch(err => {
            console.log(err);
        })
    }

    const handleSave = () => {
        let emptyFieldCount = 0;
        setBtnWarn(false);
        if (!categoryName) {
            setCategoryNameWarn(true)
            emptyFieldCount++
        } else {
            setCategoryNameWarn(false)
        }

        if (emptyFieldCount > 0) {
            setBtnWarn(true)
        } else {
            axios.post("https://api.absolutemarketresearch.com/api/Category", {
                categoryId,
                categoryName,
                categoryNameWithReportCount,
                parentCategory,
                categoryImageURL,
                isOnHomepage,
                isActive: JSON.parse(isActive)
            }).then(res => {
                console.log(res.data);
                setOpen(false)
            }).catch(err => {
                console.log(err);
            })
        }
    }
    const handleUpdate = () => {
        console.log(categoryId);
        axios.put(`https://api.absolutemarketresearch.com/api/Category/${categoryId}`, {
            categoryId,
            categoryName,
            categoryNameWithReportCount,
            parentCategory,
            categoryImageURL,
            isOnHomepage,
            isActive: JSON.parse(isActive)
        }).then(res => {
            console.log(res.data);
            setOpen(false)
        }).catch(err => {
            console.log(err);
        })
    }
    const handleDelete = () => {
        axios.delete(`https://api.absolutemarketresearch.com/api/Category/${categoryId}`).then(res => {
            console.log(res.data);
            setOpen2(false)
        }).catch(err => {
            console.log(err);
        })
    }

    const { columns, rows } = categoriesTableData({ data: filteredData, handleEditOpen, handleOpen2 });


    useEffect(() => {
        axios.get(process.env.REACT_APP_GET_ALL_CATEGORIES_API).then(res => {
            console.log(res.data);
            setStoreData(res.data);
        }).catch(err => {
            console.log(err);
        })
    }, []);


    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };
    useEffect(() => {
        if (searchTerm === '') {
            setFilteredData(storeData);
        } else {
            const filteredResults = storeData.filter(item =>
                (item.categoryName && item.categoryName.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.categoryNameWithReportCount && item.categoryNameWithReportCount.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (item.isOnHomepage && item.isOnHomepage.toLowerCase().includes(searchTerm.toLowerCase()))
            );
            setFilteredData(filteredResults);
        }
    }, [searchTerm, storeData]);

    return (
        <div className="categoryManagement">
            <DashboardLayout>
                <DashboardNavbar searchTerm={searchTerm} handleInputChange={handleInputChange} />
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                >
                                    <MDTypography variant="h6" color="white">
                                        <span>Category List</span>
                                        {/* <input type="checkbox" name="deliveryTodal" id="deliveryTodal" /> */}
                                    </MDTypography>
                                    <div>
                                        <MDBox pr={1} className="navBarInputWrap" style={{ marginRight: "20px" }}>
                                            <MDInput label="Search here" value={searchTerm} onChange={handleInputChange} style={{ width: "200px" }} />
                                        </MDBox>
                                        <Button style={{ color: "#1A73E8", backgroundColor: "#fff" }} onClick={handleOpen}>Create Category</Button>
                                    </div>
                                </MDBox>
                                <MDBox pt={3}>
                                    <DataTable
                                        table={{ columns, rows }}
                                        isSorted={false}
                                        entriesPerPage={false}
                                        showTotalEntries={false}
                                        noEndBorder
                                    />
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
            <CategoryModal
                open={open}
                handleClose={handleClose}
                handleSave={handleSave}
                handleUpdate={handleUpdate}
                isUpdate={isUpdate}
                categoryId={categoryId}
                categoryName={categoryName}
                categoryNameWithReportCount={categoryNameWithReportCount}
                parentCategory={parentCategory}
                categoryImageURL={categoryImageURL}
                isOnHomepage={isOnHomepage}
                isActive={isActive}
                setIsUpdate={setIsUpdate}
                setStoreData={setStoreData}
                setCategoryId={setCategoryId}
                setCategoryName={setCategoryName}
                setCategoryNameWithReportCount={setCategoryNameWithReportCount}
                setParentCategory={setParentCategory}
                setCategoryImageURL={setCategoryImageURL}
                setIsOnHomepage={setIsOnHomepage}
                setIsActive={setIsActive}

                btnWarn={btnWarn}
                categoryNameWarn={categoryNameWarn}
            />

            <DeleteModal
                open={open2}
                handleClose={handleClose2}
                categoryId={categoryId}
                handleDelete={handleDelete}
            />
        </div>
    );

};

export default CategoryManagement;
