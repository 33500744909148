import { Box, Button, Modal, Typography } from '@mui/material'
import React, { useState } from 'react'
import close from '../../../assets/images/close.png'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "85%",
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: "10px",
    display: "block",
    boxShadow: 24,
    p: 4,
    maxHeight: "100vh",
    overflowX: "clip",
    overflowY: "auto",
};

const CategoryModal = ({ open, handleClose, handleSave, handleUpdate, isUpdate,
    categoryId,
    categoryName,
    categoryNameWithReportCount,
    parentCategory,
    categoryImageURL,
    isOnHomepage,
    isActive,
    setCategoryId,
    setCategoryName,
    setCategoryNameWithReportCount,
    setParentCategory,
    setCategoryImageURL,
    setIsOnHomepage,
    setIsActive,

    btnWarn,
    categoryNameWarn
}) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h4" component="h2" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>{isUpdate ? "Edit" : "Create"} Category</span>
                    <img src={close} alt="" style={{ width: "20px", cursor: "pointer" }} onClick={handleClose} />
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <div className="categoryEdit editForm">
                        <div className='form'>
                            <div className="editWrap">
                                {
                                    !isUpdate &&
                                    <div className="singleEditInp">
                                        <label htmlFor="catId">category id</label>
                                        <input type="text" name="catId" id="catId" value={categoryId} onChange={e => setCategoryId(e.target.value)} />
                                    </div>
                                }
                                <div className="singleEditInp">
                                    <label htmlFor="catName">category name</label>
                                    <input type="text" name="catName" id="catName" value={categoryName} onChange={e => setCategoryName(e.target.value)} />
                                    {categoryNameWarn && <small className="warnText">*This field is required!</small>}
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="catNameWithRepCount">category name with report count</label>
                                    <input type="text" name="catNameWithRepCount" id="catNameWithRepCount" value={categoryNameWithReportCount} onChange={e => setCategoryNameWithReportCount(e.target.value)} />
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="parentCat">parent category</label>
                                    <input type="text" name="parentCat" id="parentCat" value={parentCategory} onChange={e => setParentCategory(e.target.value)} />
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="catImgUrl">category image url</label>
                                    <input type="text" name="catImgUrl" id="catImgUrl" value={categoryImageURL} onChange={e => setCategoryImageURL(e.target.value)} />
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="catIsOnHome">is on home page</label>
                                    <input type="text" name="catIsOnHome" id="catIsOnHome" value={isOnHomepage} onChange={e => setIsOnHomepage(e.target.value)} />
                                </div>
                                <div className="singleEditInp">
                                    <label htmlFor="catActive">is active</label>
                                    <select name="catActive" id="catActive" value={isActive} onChange={e => setIsActive(e.target.value)}>
                                        <option value="true">true</option>
                                        <option value="false">false</option>
                                    </select>
                                </div>
                            </div>
                            <Button onClick={handleClose} variant='contained' style={{ marginTop: "15px", marginRight: "15px", backgroundColor: "#fff" }}>Cancel</Button>
                            <Button onClick={isUpdate ? handleUpdate : handleSave} variant="contained" style={{ color: "#fff", marginTop: "15px" }} type='submit'>{isUpdate ? "Update" : "Submit"}</Button>
                            {btnWarn && <small className="warnBtnText">*Please fill all required fields!</small>}
                        </div>
                    </div>
                </Typography>
            </Box>
        </Modal>
    )
}

export default CategoryModal